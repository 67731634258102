/* Force re-enable scrollbars for the table container */
.force-scrollbar {
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 82%;
}

.force-scrollbar::-webkit-scrollbar {
  height: 10px !important;
}

.force-scrollbar::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
  border-radius: 10px !important;
}

.force-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3374b9 !important;
  border-radius: 10px !important;
  border: 2px solid #e0e0e0 !important;
}

.force-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3374b9 !important;
}
