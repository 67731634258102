.show-on-print {
  display: none !important;
}

@media print {
  @page {
    margin: 5mm 7mm; /* Set page margins for PDF */
  }

  body {
    box-sizing: border-box;
  }
  .hide-on-print {
    display: none !important;
  }

  .on-print-width {
    width: 100%;
  }

  .on-print-width-materiality {
    width: 33%;
  }

  .on-print-width-2-column {
    width: 50%;
  }

  .on-print-width-bar-chart {
    width: 60% !important;
  }

  .on-print-font {
    font-size: 25px !important;
  }

  .show-on-print {
    display: block;
  }
}
