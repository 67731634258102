.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 57vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
* {
  font-family: "Poppins";
  /* user-select: none; */
}
body {
  background-color: #fefefe !important;
  font-family: "Poppins";
}
.commentScroll {
  overflow-y: scroll;
  height: 70vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.commentScroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.commentScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  padding: 0;
  margin: 0;
}

.customScroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 57vh;
}
.customScroll::-webkit-scrollbar {
  /* display: none; */
  width: 5px;
  background: "red";
}
/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #689ed8;
  border-radius: 12px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #3374b9;
}
/* Hide scrollbar for IE, Edge and Firefox */
.customScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 5px; /* Firefox */
}
